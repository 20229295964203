import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ProgressBar from '../ProgressBar';
import * as actions from '../../actions/hosted-sites';

const $ = (num, withCents = true) => {
  const options = { style: 'currency', currency: 'USD' };
  if (!withCents) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  }
  return new Intl.NumberFormat('en-US', options).format(num);
};

const getCurrentMonthDateString = (day) => {
  const thisYear = moment().format('YYYY');
  const thisMonthNum = moment().format('MM');
  return `${thisYear}-${thisMonthNum}-${day}`;
};

function getYearData(year) {
  if (!year) {
    return [];
  }
  
  const thisYear = moment().format('YYYY');
  const yearTotals = year.get('totals');

  const getData = (month) => {
    const date = `${thisYear}-${month}`;
    let sales = yearTotals.getIn([date, 'sales'], undefined);
    if (sales) {
      sales = parseInt(sales, 10);
    }
    // for some reason when passing month number to moment().month, Jan is 0
    const prettyMonth = moment().month(month - 1).format('MMM');
    return { date: prettyMonth, sales };
  }

  return [
    getData('01', yearTotals),
    getData('02', yearTotals),
    getData('03', yearTotals),
    getData('04', yearTotals),
    getData('05', yearTotals),
    getData('06', yearTotals),
    getData('07', yearTotals),
    getData('08', yearTotals),
    getData('09', yearTotals),
    getData('10', yearTotals),
    getData('11', yearTotals),
    getData('12', yearTotals),
  ];
}

function getMonthData(month, lastMonth) {
  if (!month || !lastMonth) {
    return [];
  }

  const getPrevMonthDateString = (day) => {
    const year = moment().subtract(1, 'months').format('YYYY');
    const lastMonthNum = moment().subtract(1, 'months').format('MM');
    return `${year}-${lastMonthNum}-${day}`;
  }
  const monthTotals = month.get('totals');
  const lastMonthTotals = lastMonth.get('totals');

  const getData = (day) => {
    day = day < 10 ? `0${day}` : `${day}`;
    const date = getCurrentMonthDateString(day);
    const lastDate = getPrevMonthDateString(day);
    let sales = monthTotals.getIn([date, 'sales'], undefined);
    let prevSales = lastMonthTotals.getIn([lastDate, 'sales'], undefined);
    if (sales) {
      sales = parseInt(sales, 10);
    }
    if (prevSales) {
      prevSales = parseInt(prevSales, 10);
    }

    return { date: day, currentMonth: sales, previousMonth: prevSales };
  }

  const data = [];
  for (let i = 1; i <= 31; i++) {
    data.push(getData(i))
  }

  return data;
}

function getWeekData(week) {
  if (!week) {
    return [];
  }

  return week.get('totals').reduce((acc, data, date) => {
    const day = moment().diff(date, 'days') === 0 ? 'Today' : moment(date).format('dddd');
    acc.push({ date: day, sales: data.get('sales', undefined)});
    return acc;
  }, []);
}

const Chart = ({
  data,
  dataKeys,
  xAxisLabel,
  showLegend = false,
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        // data={data}
        data={data}
        margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 24,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
        />
        <YAxis
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
          tickFormatter={tick => $(tick, false)}
        />
        <Tooltip
          formatter={value => $(value)}
        />
        {showLegend && <Legend />}
        {dataKeys.map((dataKey, i) => {
          const stroke = i === 0 ? theme.palette.primary.main : theme.palette.secondary.main;
          return (
            <Line
              key={dataKey}
              type="monotone"
              dataKey={dataKey}
              stroke={stroke}
              strokeWidth={2}
            />
          )
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}

const Dashboard = ({
  reports,
  isFetchingReports,
  fetchDashboardReports,
}) => {
  React.useEffect(() => {
    if (reports.size === 0 && isFetchingReports === false) {
      fetchDashboardReports();
    }
  }, [reports, fetchDashboardReports, isFetchingReports]);

  if (isFetchingReports !== false) {
    return (
      <ProgressBar text="Fetching WPE Sites" progress={isFetchingReports === false ? 0 : isFetchingReports} />
    );
  }

  const yearData = getYearData(reports.get('year'));
  const monthData = getMonthData(reports.get('month'), reports.get('lastMonth'));
  const weekData = getWeekData(reports.get('week'));

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Typography component="h3" variant="h6" color="primary" gutterBottom>
              {`${moment().format('YYYY')} Monthly Sales`}
            </Typography>
            <Chart data={yearData} dataKeys={['sales']} xAxisLabel="Month" />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Typography component="h3" variant="h6" color="primary" gutterBottom>
              {`${moment().format('MMMM YYYY')} Sales vs. Previous Month`}
            </Typography>
            <Chart data={monthData} dataKeys={['currentMonth', 'previousMonth']} xAxisLabel="Day" showLegend={true} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Typography component="h3" variant="h6" color="primary" gutterBottom>
              Last 7 Days Sales
            </Typography>
            <Chart data={weekData} dataKeys={['sales']} xAxisLabel="Day" />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    reports: state.entities.wc_reports,
    isFetchingReports: state.ui.getIn(['fetching', 'wc_reports']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardReports: () => dispatch(actions.fetchDashboardReports()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
